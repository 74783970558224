import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

// modulo iii
import iiiModuleActivities from "./video.config.iii.json";
import video_default from "./video_default.json";
import { INATRANS } from "../../utils/state";

const getShuffledArr = (arr, storeInto = "videos_con_cuadrantes") => {
  const newArr = arr.slice();
  const finalArray = [];
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  /* loop para seleccionar solo 9 videos del arreglo randomizado */
  for (let j = 0; j < 5; j++) {
    // ! EDIT: ahora son 5
    finalArray.push(newArr[j]);
  }
  sessionStorage.setItem(storeInto, JSON.stringify(finalArray));
  // return newArr;
  return finalArray;
};

const _version = iiiModuleActivities;
const _example_videos = iiiModuleActivities.videos.filter(
  (v) => v.video_desc.title === "Actividad de ensayo"
);
const _rest_videos = iiiModuleActivities.videos.filter(
  (v) => v.video_desc.title === "Control de riesgo"
);
const _shuffle_videos = getShuffledArr(_rest_videos);
const _version_videos = [..._example_videos, ..._shuffle_videos];

const default_state = {
  loading: false,
  videos: video_default.videos,
  program_desc: video_default.desc,
  v: video_default.videos[0],
  v_desc: video_default.videos[0].video_desc,
  questions: video_default.videos[0].video_questions,
  layer: false,
};

const appInit = {
  loading: true,
  config: _version.config,
  videos: _version_videos,
  program_desc: _version.desc,
  v: _version_videos[0],
  v_desc: _version_videos[0]?.video_desc,
  questions: _version_videos[0]?.video_questions,
  layer: false,
};

const AppReducer = (state, action) => {
  const { payload } = action;
  console.log(action.type)
  switch (action.type) {
    /*     case 'click':
      return appClick */
    case "loading":
      return {
        ...state,
        loading: payload.loading,
      };
    case "video":
      return {
        ...state,
        v: payload.v,
        v_desc: payload.v_desc,
        questions: payload.questions,
      };
    case "layer":
      return {
        ...state,
        layer: payload.layer,
      };
    case "filter":
      return {
        ...state,
        videos: payload.videos,
        v: payload.v,
        questions: payload.questions,
      };
    case "default":
      return default_state;
    default:
      return state;
  }
};

const AppIIIContext = createContext();

export const AppIIIProvider = ({ children }) => {
  const [appIII, appIIIActions] = useReducer(AppReducer, appInit);
  const [countdown, setCountdown] = useState(null);
  const [cron, setCron] = useState(
    appIII.config === INATRANS.RISK_PREDICTION ? "00:10:00" : "00:07:00"
  );

  useEffect(() => {
    if (appIII.config) {
      console.log(appIII);
      // console.log('setting cron ')
      setCron(appIII.config === "iii" ? "00:05:00" : "00:07:00"); // changed 15 to 10 minutes
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appIII.config]);

  function createCron() {
    let countdownStart = appIII.config === "iii" ? 300 : 420; // changed 900 to 600 seconds
    // let countdown = app.config === "quest" ? 50 : 420;
    setInterval(() => {
      countdownStart--;
      setCountdown(countdownStart);
      let hours = Math.floor(countdownStart / 3600);
      let minutes = Math.floor((countdownStart - hours * 3600) / 60);
      let seconds = Math.floor(countdownStart - hours * 3600 - minutes * 60);
      setCron(
        `${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`
      );
    }, 1000);
  }

  const value = { appIII, appIIIActions, countdown, cron, createCron };
  return (
    <AppIIIContext.Provider value={value}>{children}</AppIIIContext.Provider>
  );
};

export const useAppIII = () => {
  return useContext(AppIIIContext);
};
