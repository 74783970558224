import React, { useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useModal } from '../../provider/modal'
import Dialog from '@mui/material/Dialog'
import { Quest } from './Quest'
import { createPortal } from 'react-dom'


export const ModalPlayer = ({ player, handleStart, createCron, isModuleIII = false }) => {
  const { modal_state } = useModal()
  const [open, setOpen] = React.useState(true)
  const { modal } = modal_state

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(false)
    }, 750)
    return () => setOpen(true)
  }, [modal])



  return createPortal(
    <Dialog
      open={modal}
      fullScreen
      //onClose={onModalClose}
      PaperProps={{
        style: {
          backgroundColor: "#17181a",
          boxShadow: "none"
        },
      }}>
      <Quest player={player} handleStart={handleStart} isModuleIII={isModuleIII}/>

    </Dialog>,
    document.getElementById('modal-body')
  )
}