import React, { useEffect } from "react";
import { useModal } from "../modal";
import { useAuth } from "../auth";
import { useApp } from "../app";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useAppIII } from "../app/AppIIIProvider";
// let isPosted = false
let alertReady = false;

const ConfigContext = React.createContext(null);

export const ConfigProvider = ({ children }) => {
  const [user_response, set_user_response] = React.useState("");
  const { modal_dispatch } = useModal();
  const { auth, config } = useAuth();
  const { app, appActions } = useApp();
  const { appIII, appIIIActions } = useAppIII();
  const { enqueueSnackbar } = useSnackbar();
  const [time, setTime] = React.useState(null)
  const [isConfigModuleIII , setIsConfigModuleIII] = React.useState(false)

  const initResults = {
    client: "inatrans",
    provider: "youtube",
    course_id: "",
    user_id: "",
    user_name: "",
    url_name: "",
    start_date: new Date().toLocaleDateString(),
    end_date: new Date().toLocaleDateString(),
    url_cmid: "",
    video_id: "",
    results: [],
    responseTimeResults: []
  };

  const [results, setResults] = React.useState(initResults);
  useEffect(() => {
    setResults({
      ...results,
      course_id: config.course_id,
      user_id: config.user_id,
      user_name: auth.user.user_name,
      url_name: config.url_name,
      url_cmid: config.url_cmid,
      video_id: isConfigModuleIII ? appIII.v.video_id : app.v.video_id ,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, app,appIII, isConfigModuleIII]);

  const handleConfigModuleIII = (mode) => {
    setIsConfigModuleIII(mode)
  }

  const configActions = {
    onUserResponseTimeQuestion: function (
      userResponseTime,
      title,
      correctAnswer
    ) {
      let isCorrect = null;
      /* localStorage.setItem(
        `${title}`,
        `${JSON.stringify(userResponseTime)};${isCorrect}`
      ); */

      function checkSeconds(userResponseTime, correctAnswer) {
        // Split the time string by ':' to get the hours, minutes, and seconds
        const timeParts = userResponseTime.split(":");
        // Get the seconds part (assuming the format is HH:MM or MM:SS)
        const seconds = parseInt(timeParts[1], 10);
        // Check if the seconds part is in the array
        return correctAnswer.includes(seconds);
      }
      console.log(userResponseTime, correctAnswer);
      isCorrect = checkSeconds(userResponseTime, correctAnswer);
      console.log(userResponseTime, correctAnswer, isCorrect);
      localStorage.setItem(
        `${title}`,
        `${JSON.stringify(userResponseTime)};${isCorrect}`
      );
      setResults((r) => ({
        ...r,
        responseTimeResults: [
          ...r.responseTimeResults,
          {
            name: title,
            response: userResponseTime,
            correct: isCorrect,
          },
        ],
      }));
    },
    onUserResponse: function (response, title, correct, type, isModuleIII) {
      let isExample = title.includes("Ensayo");
      console.log(title);
      set_user_response("");
      let isCorrect = null;
      switch (type) {
        case "box":
          if (response === correct) {
            isCorrect = true;
          } else {
            isCorrect = false;
          }
          break;
        case "click":
          if (response <= correct) {
            isCorrect = true;
          } else {
            isCorrect = false;
          }
          break;
        default:
          break;
      }
      if (type !== "intro") {
        if (!isExample) {
          localStorage.setItem(
            `${title}`,
            `${JSON.stringify(response)};${isCorrect}`
          );
        }
      }
      let idx;
      if (isModuleIII) {
        idx = appIII.v.video_questions.findIndex((q) => q.title === title) + 1;
      } else {
        idx = app.v.video_questions.findIndex((q) => q.title === title) + 1;
      }
      if (type !== "intro") {
        if (!isExample) {
          setResults((r) => ({
            ...r,
            results: [
              ...r.results,
              { name: title, response: response, correct: isCorrect },
            ],
          }));

          console.log({ results });
        }
      }
      if (app.config === "quest" || (appIII.config === "iii" && isModuleIII)) {
        const questions = isModuleIII ? appIII.questions : app.questions;
        const videoQuestions = isModuleIII ? appIII.v.video_questions : app.v.video_questions;

        if (idx === videoQuestions.length) {
          modal_dispatch({
        type: "none",
          });
        } else {
          modal_dispatch({
        type: "display",
        payload: {
          modal: true,
          modal_title: questions[idx].title,
          modal_type: questions[idx].modal_type,
          answers: questions[idx].answers,
          correct_answer: questions[idx].correct_answer,
        },
          });
        }
      }
    },
    generateTimer: function () {
      let seconds = this.player.getCurrentTime();
      this.m = Math.floor(seconds / 60);
      this.s = Math.floor(seconds - this.m * 60);
      return `${this.m < 10 ? "0" + this.m : this.m}:${
        this.s < 10 ? "0" + this.s : this.s
      }`;
    },
    clickEvent: function (q) {
      let isExample = q.title.includes("Ensayo");
      if (isExample) {
        enqueueSnackbar("Haz click ahora", { variant: "info" });
      }
      let start, end;
      this.layer = document.getElementById("layer");
      start = window.performance.now();
      this.layer.onclick = () => {
        end = window.performance.now();
        if (localStorage.getItem(q.title) === null) {
          if (isExample && !alertReady) {
            alertReady = true;
            enqueueSnackbar(
              `Haz hecho click en ${(end - start) / 1000} segundos`,
              { variant: "info" }
            );
          }
          if (!isExample) {
            localStorage.setItem(q.title, (end - start) / 1000 + " segundos");
          }
          this.response = (end - start) / 1000 + " segundos";
          this.onUserResponse(
            this.response,
            q.title,
            q.correct_answer,
            "click"
          );
        } else {
          return;
        }
      };
      setTimeout(() => {
        if (end === undefined) {
          this.onUserResponse(
            "3.1 segundos",
            q.title,
            q.correct_answer,
            "click"
          );
        }
      }, 3000);
    },
    postResults: async function () {
      // isPosted = true
      this.data = results;
      console.log(this.data);
      await axios
        .post("https://tipconsola.iapp.cl/api/tracking", this.data)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => console.log(e));
      setResults((prev) => (prev = initResults));
    },
    setupVideo: function (player, isModuleIII = false) {
      alertReady = false;
      // if(results.results.length > 0 && !isPosted){
      //   this.postResults()
      // }

      this.player = player;

      this.questions = isModuleIII ? appIII.questions : app.questions;
      this.player.on("playing", () => {
        isModuleIII ? appIIIActions({ type: "layer", payload: { layer: true } }) : appActions({
          type: "layer",
          payload: { layer: true },
        });
      });
      this.player.on("timeupdate", () => {
        this.timer = this.generateTimer();
        setTime(this.timer);
        console.log(isModuleIII)
        const videoTimer = isModuleIII ? appIII.v.video_timer : app.v.video_timer;
        const question = isModuleIII ? appIII.questions[0] : app.questions[0];
        // console.log(this.timer);
        // console.log(app.v.video_timer)
        if (this.timer === videoTimer) {
          switch (isModuleIII ? appIII.config : app.config) {
            case "click":
              this.clickEvent( isModuleIII ? appIII.questions[0] : app.questions[0]);
              break;
            case "quest":
              this.displayModal(question);
              setTimeout(() => {
                this.player.pause();
              }, 1000);
              break;
            case "iii":
              this.displayModal(question);
              setTimeout(() => {
                this.player.pause();
              }, 1000);
              break;
            default:
              break;
          }
        }
      });
    },
    displayModal(question) {
      modal_dispatch({
        type: "display",
        payload: {
          modal: true,
          modal_title: question.title,
          modal_type: question.modal_type,
          answers: question.answers,
          correct_answer: question.correctAnswer || question.correct_answer,
        },
      });
    },
  };

  const value = { configActions, set_user_response, user_response, time, handleConfigModuleIII };
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return React.useContext(ConfigContext);
};
