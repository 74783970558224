import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./provider/app";
import { AuthProvider } from "./provider/auth";
import { SnackbarProvider } from "notistack";
import { App } from "./App";
import { AppIIIProvider } from "./provider/app/AppIIIProvider";

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <AppProvider>
        <AppIIIProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </AppIIIProvider>
      </AppProvider>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
