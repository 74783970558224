import React, { useEffect, useState } from "react";
import { VideoWrapper } from "../../components/video-wrapper";
import { useAuth } from "../../provider/auth";
import { fetchTracking } from "../../services";
import YTPlayer from "yt-player";
import { InatransPlayerCompleted } from "../module-completed";
import { useModal } from "../../provider/modal";
import { useConfig } from "../../provider/config";
import { ModalPlayer } from "../../components/video-player/ModalPlayer";
import { Box, Step, StepLabel, Stepper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useApp } from "../../provider/app/index";
import { defineApproved } from "../../utils";
import { URL_NAMES_V2 } from "../../utils/state";
import axios from "axios";

let player = null;
let url = "https://tipconsola.iapp.cl/api/tracking";

export function ModuleIITiempoReaccionView() {
  const [isTracked, setIsTracked] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [startDate] = React.useState(new Date().toLocaleDateString());
  const { auth, config } = useAuth();
  const [finishedForNextActivity, setFinishedForNextActivity] = useState(false);

  useEffect(() => {
    if (auth.user.user_id && auth.cookies.intentos !== "test") {
      /* track = true; */
      fetchTracking(config).then((data) => {
        if (data >= auth.cookies.intentos) {
          setCompleted(true);
        }
        /* setCompleted(data); */
      });
    }
    setIsTracked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {completed || finishedForNextActivity ? (
        <InatransPlayerCompleted url_name="inatrans-tiempo-de-percepcion-y-analisis-de-riesgo"/>
      ) : (
        <InatransPlayer
          auth={auth}
          startDate={startDate}
          isTracked={isTracked}
          setFinishedForNextActivity={setFinishedForNextActivity}
        />
      )}
    </>
  );
}

const InatransPlayer = ({ auth, startDate, isTracked,setFinishedForNextActivity }) => {
  return (
    <VideoWrapper>
      {isTracked && <VideoPlayer config={auth.config} startDate={startDate} setFinishedForNextActivity={setFinishedForNextActivity} />}
      {/* <VideoPlayer config={auth.config} startDate={startDate} /> */}
    </VideoWrapper>
  );
};


const VideoPlayer = ({ startDate,setFinishedForNextActivity }) => {
  const {modal_state, modal_dispatch } = useModal();
  const [playing, setPlaying] = React.useState(true);
  const { app, appActions, createCron, countdown } = useApp();
  const { configActions, time } = useConfig();
  const { auth } = useAuth();
  const calc_stepper_id = app.videos.findIndex((v) => v === app.v)
  const stepper_idx = calc_stepper_id >= 10 ? calc_stepper_id  : calc_stepper_id; 
  const steps = app.videos.filter(
    (v) => v.video_desc.title !== "Actividad de ensayo",
  );
  const isMobile = window.innerWidth < 870;
  //const userAnswers = { ...localStorage };
  const [posted, setPosted] = useState(false);
  const [videoClicked, setVideoClicked] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [loadInstructions, setLoadInstructions] = useState(false);
  const [instructions2, setInstructions2] = useState(false);
  const [activityEnded, setActivityEnded] = useState(false);
  const [instructionsAlreadyLoaded, setInstructionAlreadyLoaded] = useState(false);

  //const [elapsedTime, setElapsedTime] = useState(null);
  //const [startTimer, setStartTimer] = useState(false)
  //const questionTimer = useTimer(0)

  useEffect(() => {
    // YTPlayer -> requiere un div con una id donde pueda colocar el iframe con el video
    console.log(app.v);
    console.log(app.config)
    console.log(modal_state)
    console.log(calc_stepper_id)
    console.log(stepper_idx)
    if(stepper_idx >= 9 && !activityEnded) {
      setActivityEnded(true)

    }
    if (player) {
      player.destroy();
      setVideoEnded(false);
      player = new YTPlayer("#player", {
        controls: 0,
        autoplay: true,
      });
      if (stepper_idx === 0 && !modal_state.modal && !instructionsAlreadyLoaded) {
        !activityEnded && console.log('load instructions')
        !activityEnded && setLoadInstructions(true)
        !activityEnded && setInstructionAlreadyLoaded(true)
      } else {
        player.load(app.v.video_id, stepper_idx < 10 ? true : false);
        configActions.setupVideo(player);
        setLoadInstructions(false)
      }
    } else {
      player = new YTPlayer("#player", {
        controls: 0,
        autoplay: true,
      });
      setVideoEnded(false);
      if (stepper_idx === 0 && !modal_state.modal && !instructionsAlreadyLoaded) {
        console.log('load instructions')
        !activityEnded && setLoadInstructions(true)
        !activityEnded && setInstructionAlreadyLoaded(true)
      } else {
        player.load(app.v.video_id, stepper_idx < 10 ? true : false);
        configActions.setupVideo(player);
        setLoadInstructions(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.v]);

  useEffect(() => {
    // console.log(countdown);
    if (countdown && countdown < 1 && !posted) {
      // console.log(countdown);
      // postResult()
      //   .then(() => {
      //     setPosted(true);
      //     /*  window.location.replace(`http://elearning.inatrans.cl/`); */
      //   })
      //   .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  useEffect(() => {
    modal_state.modal ? setPlaying(false) : setPlaying(true); // es para el background blanco
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal_state.modal]);

  /*   useEffect(() => {
    console.log(time); // TIMER INDIVIDUAL
  }, [time]); */

  const formatOutputAnswers = (data) => {
    let output = [];
    let entries = Object.entries(data);
    entries.forEach((element) => {
      let clean = {};
      let keys = element[0].split(" - ");
      let values = element[1].split(";");
      // console.log("formatOutput keys y values", keys, values);
      clean["key"] = keys[1];
      clean["rank"] = keys[0];
      clean["value"] = JSON.parse(values[0]);
      clean["correct"] = JSON.parse(values[1]);
      output.push(clean);
    });
    console.log("output final:", output);
    return output;
  };

  const formatAllQuestions = (a) => {
    let new_array = [];
    for (let i = 0; i < a.length; i++) {
      let q = a[i].video_questions;
      for (let j = 0; j < q.length; j++) {
        new_array.push({
          title: q[j].title,
          answer: "No se respondió",
          value: "no es correcto",
        });
      }
    }
    return new_array;
  };

  async function postResult() {
    if (countdown && countdown < 1) {
      let videos_array = JSON.parse(sessionStorage.getItem("videos"));
      let videos_timeResponse_array = JSON.parse(
        sessionStorage.getItem("new_videos"),
      );
      let all_questions = formatAllQuestions(videos_array);
      let all_questions_timeResponse = formatAllQuestions(
        videos_timeResponse_array,
      );
      let output = formatOutputAnswers({ ...localStorage });
      for (let n = output.length; n < all_questions.length; n++) {
        // onUserResponse(
        //   all_questions[n].answer,
        //   all_questions[n].title,
        //   all_questions[n].value,
        //   "box"
        // );
        let isExample = all_questions[n].title.includes("Ensayo");
        if (!isExample) {
          localStorage.setItem(
            `${all_questions[n].title}`,
            `${JSON.stringify(all_questions[n].answer)};${false}`,
          );
        }
        isExample = all_questions_timeResponse[n].title.includes("Ensayo");
        if (!isExample) {
          localStorage.setItem(
            `${all_questions_timeResponse[n].title}`,
            `${JSON.stringify(all_questions_timeResponse[n].answer)};${false}`,
          );
        }
        console.log(
          `${all_questions[n].title}`,
          `${JSON.stringify(all_questions[n].answer)};${false}`,
        );
      }
    }
    try {
      let output = formatOutputAnswers({ ...localStorage });
      let correctAnswers = output.filter((value) => value.correct).length;
      let approved = defineApproved({
        ca: correctAnswers,
        name: URL_NAMES_V2.MODULO_II,
      });
     /*  console.log('formattedoutputanswers localstorage')
      console.log({ output });
      console.log('localstorage')
      console.log(localStorage);
      console.log({ largo: output.length });
      console.log('correctanswers')
      console.log({ correctAnswers }); */
      const EXAMPLE_ACTIVITY_2 = "10"
      const ouputWithouExample = output.filter((value) => !value.rank.includes(EXAMPLE_ACTIVITY_2))
      // Recuperar todas las claves de localStorage
      const keys = Object.keys(localStorage);

      // Convertir las claves en sus valores correspondientes
      const values = keys.map(key => {
        return {
          key: key,
          value: localStorage.getItem(key)
        };
      });

      const filteredValuesLocalStorage = values.filter(item => !item.key.includes(EXAMPLE_ACTIVITY_2));
// Convertir filteredValuesLocalStorage a un objeto similar a localStorage
      const filteredValuesObjectLocalStorage = filteredValuesLocalStorage.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
      }, {});
      console.log(filteredValuesObjectLocalStorage)
      console.log(ouputWithouExample)
      const { data } = await axios.post(
        url,
        {
          client: auth.cookies.config,
          provider: "youtube",
          course_name: auth.cookies.course_name,
          course_id: auth.cookies.course_id,
          user_id: auth.cookies.user_id,
          user_name: auth.cookies.user_name,
          url_name: auth.cookies.url_name,
          url_cmid: auth.cookies.url_cmid,
          start_date: startDate,
          end_date: new Date().toLocaleDateString(),
          results: JSON.stringify(filteredValuesObjectLocalStorage),
          module_results: JSON.stringify({
            module_name: "ina0",
            totalanswers: ouputWithouExample.length,
            correct: correctAnswers,
            incorrect: ouputWithouExample.length - correctAnswers,
            results_detail: ouputWithouExample,
            results: approved,
          }),
        },
        {
          "Content-Type": "application/json",
        },
      );
      console.log({ data });
      modal_dispatch({
        type: "answer",
        payload: {
          modal_title: "Tus respuestas son:",
          modal_type: "answer",
          user_answers: { ...localStorage },
        },
      });
      setTimeout(() => {
        setFinishedForNextActivity(true)
      }, 50);
    } catch (err) {
      console.log(err);
    }
  }

  /* Evento de configuracion ejecutado al finalizar la reproduccion de un video */
  useEffect(() => {
    player.on("ended", () => {
      console.log("ended");
      console.log(app);
      setVideoEnded(true);
      setPlaying(false);
      let v_idx = app.videos.findIndex((v) => v === app.v) + 1;
      let v_index = v_idx === app.videos.length ? 0 : v_idx;
      console.log('entra a post?', v_idx === app.videos.length, v_idx, app.videos.length)
      if (v_idx === app.videos.length) {
        appActions({
          type: "default",
        });
        postResult();
      } else {
        /* if (stepper_idx > 5) {
          appActions({
            type: "loading",
            payload: { loading: true },
          });
          appActions({
            type: "video",
            payload: {
              v: app.videos[v_index],
              v_desc: app.videos[v_index].video_desc,
              questions: app.videos[v_index].video_questions,
            },
          });
        } */
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  const handleClick = (event) => {
    player.play();

    if (stepper_idx < 10 && !videoClicked) {
      /* if (questionTimer.isRunning) { */
      /* questionTimer.stop() */
      setVideoClicked(true);
      setVideoEnded(true);
      let title = steps[calc_stepper_id].video_questions[0].video_question;
      let correctAnswer = steps[calc_stepper_id].video_questions[0].correct_answer;
      console.log(time);
      configActions.onUserResponseTimeQuestion(time, title, correctAnswer);
      /* } */

      /* let type = isExample ? "intro" : "box";
      let idx = app.questions.findIndex((q) => q.title === modal_title);
      console.log(idx);
      console.log(type)
      console.log(app.questions)
      if (idx === app.questions.length - 1) {
        console.log('first')
        onUserResponse(user_response, modal_title, correct_answer, type);
        setTimeout(() => {
          setLoading(false);
        }, 100);
        player.play();
      } else {
        console.log('second')
        onUserResponse(user_response, modal_title, correct_answer, type);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
    }*/
    }
  };

  const handleStart = () => {
    localStorage.clear();
    const idx = app.videos.findIndex((v) => v === app.v); // + 1 se quita el + 1 porque no parte del 0
    console.log(idx);
    appActions({
      type: "loading",
      payload: { loading: true },
    });
    setTimeout(() => {
      appActions({
        type: "video",
        payload: {
          v: app.videos[idx],
          v_desc: app.videos[idx].video_desc,
          questions: app.videos[idx].video_questions,
        },
      });
      createCron();

      player.play();
      /* questionTimer.start() */
    }, 1000);
  };

  const handleNextVideo = () => {
    setVideoClicked(false);
    let v_idx = app.videos.findIndex((v) => v === app.v) + 1;
    let v_index = v_idx === app.videos.length ? 0 : v_idx;
    /* questionTimer.reset() */
    //console.log('entra a post?', v_idx === app.videos.length, v_idx, app.videos.length)
    if (v_idx === app.videos.length) {
      setLoadInstructions(false)
      
      appActions({
        type: "default",
      });
      postResult();
    } else {
      appActions({
        type: "loading",
        payload: { loading: true },
      });
      appActions({
        type: "video",
        payload: {
          v: app.videos[v_index],
          v_desc: app.videos[v_index].video_desc,
          questions: app.videos[v_index].video_questions,
        },
      });
    }
  };

  const layer_styles = {
    background: playing ? "transparent" : "#fff",
    opacity: "1",
    display: "flex",
    flexWrap: "wrap",
  };

  const StepperLoader = () => {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: "0",
            left: "0",
            margin: "0",
            zIndex: "9999"
          }}
        >
          <div
            className={`w-full flex z-[999] justify-center  transition-colors duration-300 mb-1 ${videoClicked ? "bg-zinc-50" : "bg-transparent"}`}
          >
            {/* <p>{time}</p> */}
            <p className="lg:p-3  text-3xl font-bold tracking-wide text-black/75 mb-1">
              {videoClicked && "Reaccionado!"}
            </p>
          </div>
          {stepper_idx < 10 && (
            <div
              className={`w-full flex justify-center mb-3 md:mb-3 px-10 transition-opacity ${videoEnded ? "opacity-100" : "opacity-15"} `}
            >
              <button
                disabled={!videoEnded}
                onClick={handleNextVideo}
                className="bg-blue-200 !p-1 md:!p-2 md:!px-4 rounded-md hover:bg-blue-400 text-lg"
              >
                Siguiente
              </button>
            </div>
          )}
          {!isMobile && (
            <Stepper
              activeStep={stepper_idx}
              alternativeLabel
              sx={{ color: "#fff" }}
            >
              {[...steps].map((video, idx) => (
                <Step key={idx}>
                  <StepLabel>
                    <p className="text-white text-xs">

                      {idx === 0
                        ? "Actividad de Ensayo"
                        :
                        idx === 1 ? "Ejercicio 1"
                          : ""}
                    </p>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </Box>
      </>
    );
  };

  const [open, setOpen] = React.useState(false);
  const [openTrial, setOpenTrial] = React.useState(false);

  /*
    useEffect(() => {
      openTrial && createCron()
    }, [openTrial]) */

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {
        <div
          onClick={handleClick}
          className="layer_css"
          style={layer_styles}
          id="layer"
        ></div>
      }

      <div style={{ marginTop: 30 }} id="player"></div>
      {app.v_desc.title === "Actividad Completada" ? "" : <StepperLoader />}

      <ModalPlayer
        player={player}
        handleStart={handleStart}
        createCron={createCron}
        isModuleIII={false}
      />


    
     
        <Dialog
          open={loadInstructions}

          PaperProps={{
            style: {
              backgroundColor: "#17181a",
              boxShadow: "none",
            },
          }}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="text-white" id="alert-dialog-title">
            {"Comienzo de ensayo"}
          </DialogTitle>
          <DialogContent >
            <DialogContentText
              className="text-white"
              id="alert-dialog-description"
            >
            {instructions2 ? (<><p>Estimado alumno, comienza el ejercicio 2 con una actividad de ensayo. Para el ejercicio Recuerde que usted debe visualizar 5 videos que se reproducirán de manera automática en la cual debe identificar una posible situación de riesgo. La pantalla tendrá 4 divisiones y usted tendrá que elegir en cuál cuadrante se encuentra el riesgo, seleccionando el número del cuadrante en las alternativas que se le muestren. A continuación, usted deberá responder 2 preguntas relacionadas con la actitud del conductor y la consecuencia del riesgo.</p> <p className="mt-4">Usted tendrá 10 minutos para realizar este ejercicio, pasado este tiempo su evaluación será enviada automáticamente.</p></>) : 'Estimado alumno, comienza con la actividad de ensayo y luego con el ejercicio'} 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="mx-auto"
              onClick={() => {
                //setOpenTrial(false);
                setInstructionAlreadyLoaded(true)
                setLoadInstructions(false);
                player.load(app.v.video_id, stepper_idx < 6 ? true : false);
                configActions.setupVideo(player);
              }}
            >
              Comenzar
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
};
