import React, { useEffect, useState } from 'react'
import { useAuth } from '../../provider/auth';
import { InatransPlayerCompleted } from '../module-completed';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useSnackbar } from "notistack";
import { useApp } from '../../provider/app';
import useTimer from '../../hooks/useTimer';
import { defineApproved } from '../../utils';
import { URL_NAMES } from '../../utils/state';
import axios from 'axios';
import { Loader } from '../../components/loader';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import timerIcon from "../../assets/alarm.svg";
import preguntasImported from "./preguntas.json";

const url = "https://tipconsola.iapp.cl/api/tracking";

function ModuleIVView() {
  const { auth, config } = useAuth();
  const [completed, setCompleted] = useState(false);
  const [startDate] = useState(new Date().toLocaleDateString());
  const [preguntaIndex, setPreguntaIndex] = React.useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ]);
  const [preguntas, setPreguntas] = React.useState([]);
  const [preguntasCompletadas, setPreguntasCompletadas] = React.useState([]);

  useEffect(() => {
    console.log("sorting questions");
    setPreguntas(preguntasImported.questions.sort(() => Math.random() - 0.5));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(preguntasCompletadas);
  }, [preguntasCompletadas]);

  return (
    <>
      {completed ? (
        <InatransPlayerCompleted url_name="inatrans-actitud-y-conducta" />
      ) : (
        <ActividadView
          preguntaIndex={preguntaIndex}
          startDate={startDate}
          preguntas={preguntas}
          setPreguntasCompletadas={setPreguntasCompletadas}
          preguntasCompletadas={preguntasCompletadas}
        />
      )}
    </>
  );
}

const ActividadView = ({
  preguntaIndex,
  startDate,
  preguntas,
  setPreguntasCompletadas,
  preguntasCompletadas,
}) => {
  const [intro, setIntro] = useState(true);

  return (
    <>
      {intro ? (
        <IntroActividad setIntro={setIntro} />
      ) : (
        <ActividadViewComponent
          preguntaIndex={preguntaIndex}
          startDate={startDate}
          preguntas={preguntas}
          setPreguntasCompletadas={setPreguntasCompletadas}
          preguntasCompletadas={preguntasCompletadas}
        />
      )}
    </>
  );
};

const IntroActividad = ({ setIntro }) => {
  return (
    <Dialog
      open={true}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "#17181a",
          boxShadow: "none",
        },
      }}
    >
      <Dialog
        open={true}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
            padding: "0 1rem",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-white text-center" id="alert-dialog-title">
          {"Evaluación de Actitud y conducta en la conducción"}
        </DialogTitle>
        <DialogContent>
          <div>
            <p className="text-white text-justify mb-4">
              Esta evaluación mide la actitud que usted suele tomar como
              conductor, ante diversas situaciones que se pueden presentar
              durante la conducción.
            </p>
            <span style={{ fontWeight: "bolder" }} className="text-white mt-4">
              Instrucciones:
            </span>
            <ul
              style={{
                color: "#fff",
                fontWeight: "300",
                textAlign: "left",
                marginBottom: "1rem",
                listStyleType: "inherit",
                marginLeft: "1rem",
              }}
            >
              <li>
              Debe contestar 24 preguntas que enfocadas en su experiencia como conductor.
              </li>
              <li>
              Debe seleccionar UNA OPCIÓN de las dos alternativas que se presentan, asumiendo el rol del conductor.
              </li>
              <li>
              Debe ser lo más honesto posible y contestar lo que usted suele hacer en la situación que se le presenta.
              </li>
              <li>
              Esta evaluación tiene una duración de 25 minutos.
              </li>
              <li>
              Al finalizar el tiempo, las respuestas se enviarán de forma automática.
              </li>
            </ul>

            <span style={{ fontWeight: "bolder" }} className="text-white mt-4">
              Atención:
            </span>
            <ul className="text-white text-justify mb-4 ">
              <li>
                - Recomendamos aumentar el brillo de su pantalla para una mejor
                experiencia.
              </li>
              <li>
                - Antes de comenzar, lea cuidadosamente las instrucciones de
                cada evaluación.
              </li>
              <li>
                - Si abandona, o recarga la página, las preguntas se reordenarán
                y se perderá el progreso.
              </li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions style={{ color: "#fff" }}>
          <Button onClick={() => setIntro(false)}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const ActividadViewComponent = ({ preguntaIndex, startDate,preguntas,setPreguntasCompletadas, preguntasCompletadas }) => {
    localStorage.getItem("order_actitud_y_conducta") && localStorage.removeItem("order_actitud_y_conducta");
    const [timer, setTimer] = useState(null);
    const [cron, setCron] = useState(
      /*  localStorage.getItem("countdown") ? localStorage.getItem("countdown") :  */ 1500, // 360
    );
    /*   const imgIndex = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ]; */
    const [answers, setAnswers] = React.useState([]);
    const [submitted, setSubmitted] = React.useState(false);
    const [error, setError] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const userAnswers = { ...localStorage };
    const { app, appActions } = useApp();
    const auth = useAuth();
    const questionTimer = useTimer(0);

    React.useEffect(() => {
        questionTimer.start();
    }, [answers]);

    React.useEffect(() => {
        let interval = setInterval(async () => {
            let countdown = cron - 1;
            if (countdown <= 0) {
                setCron(0);
                setTimer(0);
                clearInterval(interval);
                return await handleSubmit();
            }
            setCron(countdown);
            setTimer(createCron(countdown));
            // eslint-disable-next-line
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [cron]);

    function createCron(countdown) {
        let hours = Math.floor(countdown / 3600);
        let minutes = Math.floor((countdown - hours * 3600) / 60);
        let seconds = Math.floor(countdown - hours * 3600 - minutes * 60);

        return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
            }:${seconds < 10 ? "0" + seconds : seconds}`;
    }

/*     const formatOutputAnswers = (data) => {
        let output = [];
        let entries = Object.entries(data);
        console.log({ entries });
        data.forEach((element) => {
            let clean = {};
            let keys = element[0];
            let values = element[1].split(";");
            clean["key"] = keys;
            clean["value"] = JSON.parse(values[0]);
            clean["correct"] = JSON.parse(values[1]);
            clean["responseTime"] = JSON.parse(values[2]);
            output.push(clean);
        });
        console.log({ output });
        return output;
    }; */

    const handleSubmit = async () => {
        //let output = formatOutputAnswers(preguntasCompletadas);
        //let correctAnswers = output.filter((value) => value.correct).length;
        let approved = defineApproved({
            ca: '',
            name: URL_NAMES.MODULO_IV,
        });
        console.log({
            client: auth.config.client,
            provider: auth.config.provider,
            course_name: auth.auth.cookies.course_name,
            course_id: auth.config.course_id,
            user_id: auth.config.user_id,
            user_name: auth.auth.cookies.user_name,
            url_name: auth.auth.cookies.url_name,
            url_cmid: auth.auth.cookies.url_cmid,
            results: JSON.stringify(preguntasCompletadas),
            start_date: startDate,
            end_date: new Date().toLocaleDateString(),
            module_results: {
                module_name: "ina4",
                totalanswers: 24,
                results_detail: preguntasCompletadas,
                results: approved,
                elapsedSeconds: cron <= 0 ? 360 : Math.abs(cron - 360), // NUEVO, tiempo que tardó en terminar la actividad.
            },
        });
        await axios
            .post(url, {
                client: auth.config.client,
                provider: auth.config.provider,
                course_name: auth.auth.cookies.course_name,
                course_id: auth.config.course_id,
                user_id: auth.config.user_id,
                user_name: auth.auth.cookies.user_name,
                url_name: auth.auth.cookies.url_name,
                url_cmid: auth.auth.cookies.url_cmid,
                results: JSON.stringify(preguntasCompletadas),
                start_date: startDate,
                end_date: new Date().toLocaleDateString(),
                module_results: JSON.stringify({
                    module_name: "ina4",
                    totalanswers: 24,
                    results_detail: preguntasCompletadas,
                    results: approved,
                    elapsedSeconds: cron <= 0 ? 360 : Math.abs(cron - 360), // NUEVO, tiempo que tardó en terminar la actividad.
                }),
            })
            .then((data) => {
                console.log(data);
                //mostrar alerta publicacion exitosa
                enqueueSnackbar(`Formulario enviado`, { variant: "success" });
                //luego mostrar la pantalla de publicacion exitosa, luego un boton para redireccionar a la proxima evaluacion
                setSubmitted(true);
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(`Error al enviar Formulario`, { variant: "error" });
                alert(
                    "ERROR AL ENVIAR EL FORMULARIO, CONTACTESE CON SERVICIO AL CLIENTE !",
                );
                setError(true);
            });
    };

    React.useEffect(() => {
        //enqueueSnackbar(`Cada imagen dispone de 1 intentos`, { variant: "info" });
        for (let i = 1; i <= 20; i++) {
            localStorage.removeItem(`${i}`);
        }
        // localStorage.clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const styles = {
        wrapper: {
            padding: "70px 0px",
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
        },
        header: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            borderBottomWidth: 0.1,
            borderBottomColor: "rgb(202 208 215)",
            borderBottomStyle: "solid",
            padding: "0 0 12px 0",
        },
        headerText: {
            textTransform: "uppercase",
            color: "rgb(202 208 215)",
            fontWeight: 700,
            fontFamily: "Roboto,sans-serif",
            letterSpacing: ".02rem",
        },
        headerTextLight: {
            fontFamily: "Roboto,sans-serif",
            color: "rgb(202, 208, 215)",
            fontSize: ".75rem",
            fontWeight: 700,
            letterSpacing: ".06rem",
            lineHeight: "1rem",
            textTransform: "uppercase",
        },
        subtitle: {
            fontFamily: "Roboto,sans-serif",
            color: "rgb(202 208 215)",
            fontWeight: 700,
        },
        subtitleLight: {
            marginTop: 12,
            fontFamily: "Roboto,sans-serif",
            color: "rgb(202 208 215)",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "1.75rem",
        },
        completed: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            marginTop: "20%",
            padding: "1.5rem",
        },
        topCompleted: {
            color: "white",
            backgroundColor: "rgb(52, 152, 219)",
            padding: "1rem",
            boxShadow: "5px 5px 12px 5px #9d9b9b",
            borderBottom: "1px solid #1979ac",
        },
        bottomCompleted: {
            margin: "1rem",
            flexDirection: "column",
            color: "black",
            fontWeight: "500",
        },
        next: {
            backgroundColor: "rgb(52, 152, 219)",
            display: "flex",
            color: "white",
            justifyContent: "center",
            padding: "0.5rem 1.5rem",
        },
        navigateButton: {
            display: "flex",
            color: "black",
            justifyContent: "center",
            padding: "0.5rem 1.5rem",
            cursor: "pointer",
        },
    };

    React.useEffect(() => {
        if (!submitted) {
            window.onbeforeunload = function (event) {
                return "Si abandonas o recargas la páginas, las preguntas ser reordenarán y se perderá el progreso.";
            };
        } else {
            window.onbeforeunload = function (event) {
                // return "Si abandonas o recargas la páginas, las preguntas ser reordenarán y se perderá el progreso.";
            };
        }
    }, [submitted]);

    return (
        <>
            <p
                style={{
                    backgroundColor: "rgb(52, 152, 219)",
                    padding: ".2rem 1rem",
                    borderRadius: "10px",
                    position: "absolute",
                    zIndex: 999999999999,
                    top: 70,
                    left: 40,
                }}
            >
                <img
                    src={timerIcon}
                    style={{ marginRight: 10, marginBottom: 3 }}
                    alt="..."
                />
                {timer}
            </p>
            <div style={styles.wrapper} className="max-w-[1240px]">
                {app.loading ? (
                    <Loader app={app} appActions={appActions} />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        {!submitted &&
                            preguntas.map((pregunta, idx) => {
                                return (
                                    <QuestionComponent
                                        key={idx}
                                        pregunta={pregunta}
                                        currQuestion={idx}
                                        answers={answers}
                                        setAnswers={setAnswers}
                                        timer={questionTimer}
                                        setPreguntasCompletadas={setPreguntasCompletadas}
                                        preguntasCompletadas={preguntasCompletadas}
                                    />

                                );
                            })}
                        {preguntasCompletadas.length === 24 && !submitted && (
                            <div style={styles.completed}>
                                <h1 style={styles.topCompleted}>
                                    24 preguntas completadas{" "}
                                    <CheckCircleIcon
                                        sx={{ color: "#6dbb6d", fontSize: "40px" }}
                                    />
                                </h1>
                                <p style={styles.bottomCompleted}>
                                    Para confirmar y enviar pulse el boton:
                                </p>
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                    Enviar
                                </button>
                            </div>
                        )}
                        {submitted && !error && (
                            <div style={styles.completed}>
                                <h1 style={styles.topCompleted}>Formulario Enviado</h1>
                                <div
                                    className="linkNavigate flex items-center"
                                    style={styles.navigateButton}
                                >
                                    Para ingresar a otros modulos, regresa a campus virtual&nbsp;
                                    <a
                                        style={{ textDecoration: "underline" }}
                                        href={`http://elearning.inatrans.cl/course/view.php?id=${auth.auth.cookies.course_id}`}
                                    >
                                        <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                                            aquí
                                        </Button>
                                    </a>
                                </div>
                                { 
            <p className="lead mt-3 text-black">
            Para regresar a la lista de actividades:{" "}
            <a
              style={{ textDecoration: "underline",marginLeft: "5px" }}
              href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
              >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};


const QuestionComponent = ({ pregunta, currQuestion, answers,
    setAnswers, timer, setPreguntasCompletadas, preguntasCompletadas }) => {
    const [res, setRes] = useState(false);
    const isCurrent = preguntasCompletadas.length === currQuestion ? true : false;
    const [, setFinished] = React.useState(false);
    const [userResponse, setUserResponse ] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    
    

    const handleNext = () => {
       /*  if (res) {
            let temp = answers.filter((r) => r.index !== pregunta.number);
            let final = [...temp, res].sort((a, b) => a - b);
            timer.reset();
            setAnswers(final);
            setFinished(true);
        } */
       if(completed){
              /* let data = {
                index: pregunta.number,
                value: userResponse,
                time: timer.elapsedTime,
              } */
              setPreguntasCompletadas(prev => [...prev, {pregunta: pregunta.question, numero: pregunta.number, respuesta:userResponse}])
              setCompleted(false)
       }
    };
    
/*     const setSelected = (idx) => {
        let data = {
            index: pregunta.number,
            value: currQuestion,
            time: timer.elapsedTime,
          };
        setRes(data);
      }; */


    return (
        <>
            <div className={`p-4 bg-[#3498db]/90 w-[80%] mb-10 relative h-auto mt-12 md:mt-14 px-4 md:px-4 `}style={{
                display: isCurrent ? "unset" : "none",
                zIndex: "1",
            }} >
                <div className='min-h-14'>

                <p className='text-xl tracking-wide'>{currQuestion + 1}. {pregunta.question}</p>
                </div>
                <RadioGroup
                    aria-label="gender"
                    name="radio-buttons-group"
                >
                    <FormControlLabel
                        sx={{ color: "#fff", margin: "0.1rem 0" }}
                        control={<Radio />}
                        label='Si'
                        value='si'
                        onChange={(e) =>  {
                            setUserResponse(e.target.value)
                            setCompleted(true)
                        }} 
                    />
                    <FormControlLabel
                        sx={{ color: "#fff", margin: "0.1rem 0" }}
                        control={<Radio />}
                        label='No'
                        value='no'
                        onChange={(e) => {
                            setUserResponse(e.target.value)
                            setCompleted(true)
                        }}
                    />

                </RadioGroup>
            </div>

                <button style={{
                    display: isCurrent ? "unset" : "none",
                }} disabled={!completed} className={`bg-slate-300 p-3 rounded-md ${completed ? 'hover:bg-slate-200 hover:cursor-pointer transition-colors' : 'opacity-45 '} `}/*  style={containerStyles.next} */ onClick={handleNext}>
                    <h5 /* style={textstyles.light} */>Siguiente</h5>
                </button>

        </>
    )
}



export default ModuleIVView